import React, { useState, forwardRef, useImperativeHandle } from "react";
import { IQADetails } from "../../types/QATypes";
import { Button, Modal } from "antd";

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item: IQADetails;
}

export const QuestionAnswerModal = ({
  isModalOpen,
  setIsModalOpen,
  item,
}: IProps) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={item.question}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          Return
        </Button>,
      ]}
    >
      <p style={{ whiteSpace: "pre-line" }}>{item.answer}</p>
    </Modal>
  );
};
