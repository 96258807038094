import React, { useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { Form, Input, Button, Checkbox, Card, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { api, appInfo } from "../config";
const { Title } = Typography;

export const LoginPage = () => {
  let auth = useAuth();
  const onFinish = (values: any) => {
    fetch(`${api.authRoutes.login}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ email: values.email, password: values.password }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status && json.status === "success") {
          getUserDetails();
        } else {
          message.error("Something went wrong while signin!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserDetails = () => {
    fetch(`${api.userRoutes.userDetails}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.data) {
          if (json.data.user) {
            auth?.login(json.data.user);
          } else {
            message.error("Something went wrong while signin!");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleForgotPassword = (e: any) => {
    e.preventDefault();
    console.log("Handle password recovery logic here");
  };

  const handleRegister = (e: any) => {
    e.preventDefault();
    console.log("Handle registration logic here");
  };

  const cookieExists = (cookieName: string) => {
    let cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.indexOf(cookieName + "=") === 0) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (cookieExists("token")) {
      getUserDetails();
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card style={{ width: 500 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Title level={2}>{appInfo.name}</Title>
        </div>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              block
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
