import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import "../styling/index.css";
import { Layout, Menu, Breadcrumb, message } from "antd";
import { QuestionCircleOutlined, DatabaseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { LogoutComp } from "../components/Logout";
import { useLocalStorage } from "../hooks/useLocalStorage";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

export const AdminLayout = ({ children }: any) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();
  const location = useLocation();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const onCollapse = (collapsed: boolean) => {
    setIsCollapsed(collapsed);
  };

  const switchPage = (route: string) => {
    navigate(route);
  };

  const getSelectedKey = () => {
    const currentRoute = location.pathname;
    switch (currentRoute) {
      case "/qa":
        return "1";
      case "/admin/qa":
        return "2";
      default:
        return "-1";
    }
  };

  const hasQAAdminAccess = () => {
    for (let role of user.roles) {
      if (role.name == "QA") {
        return true;
      }
    }
    return false;
  };

  const notEnoughPermissions = () => {
    message.info("Not enough permissions!");
  };

  getSelectedKey();
  return (
    <div style={{ overflowY: "hidden" }}>
      <Layout style={{ height: "100vh", minWidth: "720px" }}>
        <Sider collapsible collapsed={isCollapsed} onCollapse={onCollapse}>
          <div className="logo"></div>
          <div style={{ paddingLeft: "12px", color: "white" }}>
            <h1 style={{ fontSize: "1rem" }}>Hello {user.name}</h1>
          </div>
          <Menu
            theme="dark"
            defaultSelectedKeys={[getSelectedKey()]}
            mode="inline"
            style={{
              height: "calc(100% - 32px - 48px - 21px - 21px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Menu.Item
              key="1"
              onClick={() => {
                switchPage("/qa");
              }}
            >
              <QuestionCircleOutlined />
              <span>QA</span>
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={() => {
                hasQAAdminAccess()
                  ? switchPage("/admin/qa")
                  : notEnoughPermissions();
              }}
            >
              <DatabaseOutlined />
              <span>Admin</span>
            </Menu.Item>
            <div style={{ marginTop: "auto" }}></div>
            <LogoutComp key="12" />
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Content style={{ margin: "0 16px" }}>
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Memo2</Breadcrumb.Item>
            </Breadcrumb>
            <div
              className="site-layout-background"
              style={{ padding: 24, height: "100%" }}
            >
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>Memo2 QA @ 2023</Footer>
        </Layout>
      </Layout>
    </div>
  );
};
