export {};
const baseUrl = "https://alpha-login.thxapp.services/api";

const baseQaPath = "qa";
const qaRoutes = {
  getAll: `${baseUrl}/${baseQaPath}/all`,
  create: `${baseUrl}/${baseQaPath}/create`,
  edit: `${baseUrl}/${baseQaPath}/edit`,
  delete: `${baseUrl}/${baseQaPath}/delete`,
  retrieve: `${baseUrl}/${baseQaPath}/`,
};

const baseAuthPath = "auth";
const authRoutes = {
  logout: `${baseUrl}/${baseAuthPath}/logout`,
  login: `${baseUrl}/${baseAuthPath}/login`,
};

const baseUserPath = "users";
const userRoutes = {
  userDetails: `${baseUrl}/${baseUserPath}/me`,
};

const api = {
  qaRoutes,
  authRoutes,
  userRoutes,
};

const appInfo = {
  name: "MEMO2 QA",
  version: "V1",
};

export { api, appInfo };
