import React, { useEffect, useState } from "react";
import { IQADetails } from "../../types/QATypes";
import { useHover } from "@uidotdev/usehooks";

interface IProps {
  item: IQADetails;
  onPressBinder: (item: IQADetails) => void;
}

export const FoundQuestion = ({ item, onPressBinder }: IProps) => {
  const [isViewing, setIsViewing] = useState(false);
  const itemStyle = {
    color: "rgb(27, 28, 30)",
    marginTop: ".5rem",
    fontWeight: 400,
    maxWidth: "50vw",
  };

  return (
    <li
      className="py-4"
      style={{
        borderColor: "rgb(229 231 235 / 1)",
        listStyle: "none",
        borderBottom: "1px solid rgb(229, 231, 235)",
        borderBottomColor:
          "rgb(229, 231, 235) rgb(229, 231, 235) rgb(229, 231, 235) rgb(229, 231, 235)",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        maxWidth: "100%",
        cursor: "pointer",
      }}
      onClick={() => {
        onPressBinder(item);
      }}
    >
      <h2
        style={{
          fontWeight: 600,
          fontSize: "1.125rem",
          lineHeight: "1.75rem",
          marginBottom: isViewing ? "15px" : "-1rem",
        }}
      >
        {item.question}
      </h2>
      <p
        className="text-gray-600 dark:text-gray-400 mt-2"
        style={{
          overflowY: "hidden",
          whiteSpace: "pre-line",
          transform: isViewing ? "scaleY(1)" : "scaleY(0)",
          maxHeight: isViewing ? "fit-content" : "0rem",
          transition: "all 0.3s",
          transformOrigin: "top",
          transitionTimingFunction: "ease-in-out",
          ...itemStyle,
        }}
      >
        {item.answer}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "70px",
          color: "#7f7fff",
        }}
        onClick={() => setIsViewing(!isViewing)}
      >
        {!isViewing ? "Read more" : "Show less"}
        {!isViewing ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-caret-down"
            viewBox="0 0 16 16"
          >
            <path d="M8 10.5a1 1 0 0 1-.64-.23l-3.5-3a1 1 0 1 1 1.28-1.54L8 8.3l3.86-2.57a1 1 0 0 1 1.28 1.54l-3.5 3a1 1 0 0 1-.64.23z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-caret-up"
            viewBox="0 0 16 16"
          >
            <path d="M8 5.5a1 1 0 0 1 .64.23l3.5 3a1 1 0 1 1-1.28 1.54L8 7.7 4.14 10.27a1 1 0 0 1-1.28-1.54l3.5-3a1 1 0 0 1 .64-.23z" />
          </svg>
        )}
      </div>
    </li>
  );
};
