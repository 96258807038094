import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { message } from "antd";
import { api } from "../config/index";

const AuthContext = createContext({
  user: null,
  login: async (data: any) => {},
  logout: () => {},
});

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data: any) => {
    setUser(data);
    message.success("Successfully logged in!");
    navigate("/qa");
  };

  // call this function to sign out logged in user
  const logout = () => {
    fetch(`${api.authRoutes.logout}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "success") {
          setUser(null);
          message.success("Successfully logged out!");
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
