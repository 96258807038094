import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <h1>Oops! You seem to be lost.</h1>
            <p>Here are some helpful links:</p>
            <Link to='/'>Default</Link>
            <Link to='/qa'>qa</Link>
        </div>
    )
}