import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import "../styling/index.css";
import { AutoComplete, Input } from "antd";
import { AdminLayout } from "../layouts/AdminLayout";
import { Row } from "antd";
import { api } from "../config";
import debounce from "lodash.debounce";
import { FoundQuestion } from "../components/QA/FoundQuestion";
import { IQADetails } from "../types/QATypes";
import { QuestionAnswerModal } from "../components/QA/QAModal";

const style: React.CSSProperties = {
  background: "#0092ff",
  padding: "8px 8px",
};

export const DashboardPage = () => {
  const [modalQuestionItem, setModalQuestionItem] = useState<IQADetails>(
    {} as IQADetails
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [qaData, setQaData] = useState<Array<IQADetails>>([]);

  const [matchingQuestions, setMatchingQuestions] = useState<Array<IQADetails>>(
    []
  );

  const [searchValue, setSearchValue] = useState<string>("");

  const fetchData = () => {
    fetch(`${api.qaRoutes.getAll}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json.data.all);
        setQaData(json.data.all);

        setMatchingQuestions(json.data.all);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const debouncedFindQuestions = useCallback(
    debounce((searchValue: string) => {
      const foundQuestions = qaData.filter((item: any) =>
        item.question.toLowerCase().includes(searchValue.toLowerCase())
      );
      setMatchingQuestions(foundQuestions);
    }, 300), // Adjust the debounce delay as needed
    [qaData]
  );

  const findQuestions = (searchValue: string) => {
    const foundQuestions = qaData.filter((item: any) =>
      item.question.toLowerCase().includes(searchValue.toLowerCase())
    );
    setMatchingQuestions(foundQuestions);
  };

  const changeSearchValue = useCallback(
    (value: any) => {
      console.log(value);
      setSearchValue(value);
      debouncedFindQuestions(value);
    },
    [debouncedFindQuestions]
  );

  const onPressItem = (pressedItem: IQADetails) => {
    // setModalQuestionItem(pressedItem);
    // showModal();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AdminLayout>
      <Row gutter={[16, 24]} style={{ height: "100%" }}>
        <div
          style={{
            paddingLeft: "1rem",
            paddingRight: "1rem",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            height: "100%",
          }}
        >
          <div
            style={{
              maxWidth: "488px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <h1
              className="text-3xl font-bold text-center mb-6"
              style={{ textAlign: "center" }}
            >
              Search for Questions and Answers
            </h1>
            <AutoComplete
              options={matchingQuestions.map((item: any) => ({
                label: item.question,
                value: item.question,
              }))}
              onSelect={(value) => findQuestions(value)}
              onSearch={(value) => findQuestions(value)}
              placeholder="Type your question here"
              value={searchValue}
              onChange={(value) => changeSearchValue(value)}
              style={{ width: "100%" }}
            >
              {matchingQuestions.map((item: any, index: number) => (
                <AutoComplete.Option key={index} value={item.question}>
                  <h2>{item.question}</h2>
                  <p>{item.answer}</p>
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </div>
          <div
            className="questions"
            style={{
              overflow: "auto",
              maxHeight: "calc(75% - 1rem)",
              marginTop: "1rem",
            }}
          >
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              {matchingQuestions ? (
                matchingQuestions.map((item: IQADetails, index: number) => {
                  return (
                    <FoundQuestion item={item} onPressBinder={onPressItem} />
                  );
                })
              ) : (
                <div></div>
              )}
            </ul>
          </div>
        </div>
        <QuestionAnswerModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          item={modalQuestionItem}
        ></QuestionAnswerModal>
      </Row>
    </AdminLayout>
  );
};
