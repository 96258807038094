import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useParams } from "react-router-dom";

import { LoginPage } from "./pages/Login";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AuthLayout } from "./layouts/AuthLayout";
import { DashboardPage } from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import { AddQa } from "./pages/AddQA";
import { QaAdminPage } from "./pages/QAAdmin";
import { EditQAPage } from "./pages/EditQA";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route index path="/" element={<LoginPage />} />
          <Route
            path="/qa"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/qa"
            element={
              <ProtectedRoute>
                <QaAdminPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/qa/create"
            element={
              <ProtectedRoute>
                <AddQa />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/qa/edit/:id"
            element={
              <ProtectedRoute>
                <EditQAPage />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
