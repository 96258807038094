import React, { useEffect, useState } from "react";
import "../styling/index.css";
import { Typography, message } from "antd";
import { QuestionCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { AdminLayout } from "../layouts/AdminLayout";
import { Form, Input, Button, Select, Card } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { api } from "../config";
import { Loading } from "../components/Loading";

const { Title } = Typography;
const { TextArea } = Input;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface qaItem {
  answer: string;
  question: string;
}

export const EditQAPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [qaItem, setQAItem] = useState<qaItem>({
    question: "",
    answer: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    fetch(`${api.qaRoutes.retrieve}${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        if (json.status === "success") {
          setQAItem(json.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateUser = (values: any) => {
    fetch(`${api.qaRoutes.edit}`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        qa_id: Number(id),
        question: values.question,
        answer: values.answer,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "success") {
          message.success("Successfully changed qa!");
          navigate("/admin/qa");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <AdminLayout>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card style={{ width: 500 }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Title level={2}>Edit qa</Title>
          </div>
          <Form
            name="edit_qa"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={updateUser}
          >
            <Form.Item
              name="question"
              rules={[
                { required: true, message: "Please input your question!" },
              ]}
              initialValue={qaItem.question}
            >
              <Input
                prefix={
                  <QuestionCircleOutlined className="site-form-item-icon" />
                }
                placeholder="Question..."
              />
            </Form.Item>
            <Form.Item
              name="answer"
              rules={[{ required: true, message: "Please input your Answer!" }]}
              initialValue={qaItem.answer}
            >
              <TextArea placeholder="Answer..." rows={6} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                block
              >
                Update qa
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </AdminLayout>
  );
};
