import { Col, Flex, Row, Spin } from "antd";
import "../styling/index.css";
import "../styling/index.css";

export const Loading = () => {
  return (
    <Row align="middle" style={{ height: "100vh" }}>
      <Col style={{ margin: "auto" }}>
        <Flex align="center" gap="middle">
          <Spin size="small" />
          <Spin size="large" />
          <Spin size="small" />
        </Flex>
        <p style={{ textAlign: "center", color: "black", fontWeight: "600" }}>
          Loading...
        </p>
      </Col>
    </Row>
  );
};
