import React from "react";
import ReactDOM from "react-dom";
import "../styling/index.css";
import { Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useAuth } from "../hooks/useAuth";
import "../styling/index.css";

export const LogoutComp = () => {
  let auth = useAuth();

  const logout = () => {
    auth.logout();
  };

  return (
    <Menu.Item
      key="99"
      onClick={() => {
        logout();
      }}
      style={{ paddingLeft: "24px" }}
    >
      <LogoutOutlined />
      <span>Logout</span>
    </Menu.Item>
  );
};
