import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import "../styling/index.css";
import { AdminLayout } from "../layouts/AdminLayout";

import { useEffect } from "react";
import {
  Button,
  Table,
  Popconfirm,
  message,
  Modal,
  Input,
  Typography,
} from "antd";

import {
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  EditOutlined,
  LockOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { useNavigate } from "react-router-dom";
import { api } from "../config";

interface user {
  id: number;
  name: string;
  email: string;
  createdAt: string;
  password: string;
  photo: string;
  role_name: string;
  updatedAt: string;
  verified: boolean;
}

export const QaAdminPage = () => {
  const [qa, setQa] = useState([] as Array<user>);

  const [size, setSize] = useState<SizeType>("small");
  const [medium, setMedium] = useState<SizeType>("middle");

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(`${api.qaRoutes.getAll}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((json) => {
        setQa(json.data.all);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteQA = (id: number) => {
    fetch(`${api.qaRoutes.delete}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        qa_id: id,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        setQa(qa.filter((qa_f) => qa_f.id !== id));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const confirmedDelete = (record: any) => {
    deleteQA(record.id);
    message.success("Deleted record!");
  };

  const cancel = (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    message.info("Cancelled action!");
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      width: "35%",
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      render: (text: any, record: any) => (
        <p
          className="text-gray-600 dark:text-gray-400 mt-2"
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {record.answer}
        </p>
      ),
      width: "35%",
    },
    {
      title: "Actions",
      dataIndex: "key",
      key: "key",
      render: (text: any, record: any) => (
        <div>
          <Popconfirm
            title="Edit qa"
            description="Are you sure to edit this qa?"
            icon={<QuestionCircleOutlined style={{ color: "green" }} />}
            onConfirm={(e) => {
              console.log(record);
              navigate({
                pathname: `/admin/qa/edit/${record.id}`,
              });
            }}
            onCancel={(e) => {
              cancel(e);
            }}
          >
            <Button
              style={{
                background: "rgb(127, 239, 76)",
                borderColor: "rgb(127, 239, 76)",
                marginRight: "5px",
              }}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              size={size}
            />
          </Popconfirm>
          <Popconfirm
            title="Delete qa"
            description="Are you sure to delete this qa?"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={(e) => {
              confirmedDelete(record);
            }}
            onCancel={(e) => {
              cancel(e);
            }}
          >
            <Button
              style={{
                background: "rgb(247, 75, 75)",
                borderColor: "rgb(247, 75, 75)",
              }}
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              size={size}
            />
          </Popconfirm>
        </div>
      ),
      width: "15%",
    },
  ];

  return (
    <AdminLayout>
      <Button
        style={{ marginBottom: "1rem" }}
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        size={medium}
        onClick={() => {
          navigate("/admin/qa/create");
        }}
      />
      <Table
        pagination={{ pageSize: 6 }}
        columns={columns}
        dataSource={qa}
        rowKey="id"
        scroll={{ y: "59vh" }}
      />
    </AdminLayout>
  );
};
